@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  /* font-family: "Source Sans Pro", sans-serif; */
 
  /* background: #F4F2EE; */
  font-family: "DM Serif Text", serif;
  
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 0px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #d4d4d4;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #747474;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.pdf-page {
  margin: 0;
  padding: 0;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
